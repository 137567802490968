
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import Vue from "vue"
import { NearestSnapshotResponsePayload } from "@evercam/shared/types/recording"
import axios from "@evercam/shared/api/client/axios"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { AxiosError, CancelTokenSource } from "axios"
import { AnalyticsEvent } from "@evercam/shared/types"

enum SnapshotType {
  Before = "before",
  After = "after",
}

export default Vue.extend({
  components: { EvercamLoadingAnimation },
  data() {
    return {
      noSnapshot: "/unavailable.jpg",
      snapshotLoading: { before: true, after: true },
      snapshotCancelTokens: { before: [], after: [] },
    }
  },
  computed: {
    ...mapStores(useSnapshotStore, useCompareStore, useCameraStore),
    evercamLogo() {
      return require("~/static/EVERCAM white.png")
    },
    isSnapshotLoading(): boolean {
      return this.snapshotLoading.before || this.snapshotLoading.after
    },
  },
  watch: {
    "compareStore.beforeTimestamp"(timestamp) {
      this.loadSnapshot(timestamp, SnapshotType.Before)
    },
    "compareStore.afterTimestamp"(timestamp) {
      this.loadSnapshot(timestamp, SnapshotType.After)
    },
  },
  async mounted() {
    this.initSnapshots()
  },
  methods: {
    initSnapshots() {
      const { beforeTimestamp, afterTimestamp } = this.compareStore
      if (!beforeTimestamp || !afterTimestamp) {
        return
      }
      Promise.all([
        this.loadSnapshot(beforeTimestamp, SnapshotType.Before),
        this.loadSnapshot(afterTimestamp, SnapshotType.After),
      ])
    },
    async loadSnapshot(timestamp, snapshotType: SnapshotType) {
      if (!timestamp) {
        return
      }
      this.snapshotLoading[snapshotType] = true
      if (timestamp === this.snapshotStore.oldestSnapshotTimestamp) {
        this.setSnapshot(this.snapshotStore.oldestSnapshot.data, snapshotType)

        return
      }

      if (timestamp === this.snapshotStore.latestSnapshotTimestamp) {
        this.setSnapshot(this.snapshotStore.latestSnapshot.data, snapshotType)

        return
      }

      try {
        // Cancel the previous requests
        this.cancelRequests(snapshotType)

        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        this.addCancelToken(cancelToken, snapshotType)
        const response: NearestSnapshotResponsePayload =
          await EvercamApi.recordings.nearest(
            this.cameraStore.selectedCameraExid,
            timestamp,
            null,
            {
              cancelToken: cancelToken.token,
            }
          )
        const snapshot = response?.snapshots?.[0]?.data
        this.setSnapshot(snapshot || this.noSnapshot, snapshotType)
      } catch (error) {
        if (this.$axios.isCancel(error as AxiosError)) {
          return
        }
        this.$notifications.error({
          text: this.$t("content.generic_error_message"),
          error,
        })
        this.setSnapshot(this.noSnapshot, snapshotType)
      }
    },
    setSnapshot(snapshot, snapshotType: SnapshotType) {
      this.compareStore.snapshot[snapshotType] = snapshot
      this.snapshotLoading[snapshotType] = false
    },
    addCancelToken(token: CancelTokenSource, snapshotType: SnapshotType) {
      this.snapshotCancelTokens[snapshotType].push(token)
    },
    resetCancelTokens(snapshotType: SnapshotType) {
      this.snapshotCancelTokens[snapshotType] = []
    },
    cancelRequests(snapshotType: SnapshotType) {
      const cancelTokens = this.snapshotCancelTokens[snapshotType]
      if (cancelTokens?.length) {
        cancelTokens.forEach((t) => {
          t.cancel("Operation canceled due to new request")
        })
        this.resetCancelTokens(snapshotType)
      }
    },
    trackCompareSlide() {
      this.$analytics.saveEvent(AnalyticsEvent.CompareSlide)
    },
  },
})
