
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import Vue from "vue"
import { CompareRequestPayload, Compare } from "@evercam/shared/types/compare"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useProjectStore } from "@/stores/project"
import { useLayoutStore } from "@/stores/layout"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default Vue.extend({
  components: { CopyToClipboardBtn },
  props: {
    beforeDate: {
      type: String,
      default: "",
    },
    afterDate: {
      type: String,
      default: "1970-01-01T00:00:00+00:00",
    },
    timezone: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => {},
    },
    cameraExid: {
      type: String,
      default: "",
    },
    isDroneView: {
      type: Boolean,
      default: false,
    },
    showActivator: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      processing: false,
      created: false,
      embedCode: "",
      gifUrl: "",
      mp4Url: "",
      mp4Loading: false,
      gifLoading: false,
      title: "",
      stepper: 1,
      compareCreationError: false,
      downloadTypes: {
        gif: "gif",
        mp4: "mp4",
      },
    }
  },
  computed: {
    ...mapStores(useMediaHubStore, useProjectStore, useLayoutStore),
    mediaHubLink() {
      return `${this.projectStore.projectRoute}/media-hub`
    },
    cameraName() {
      return this.projectStore.selectedProjectCameras.find(
        (camera) => camera?.id === this.cameraExid
      ).name
    },
  },
  watch: {
    beforeDate: function () {
      this.updateTitle()
    },
    afterDate: function () {
      this.updateTitle()
    },
    dialog(visibilityStatus) {
      this.$analytics.saveEvent(AnalyticsEvent.CompareToggleExportDialog, {
        visible: visibilityStatus,
      })
    },
  },
  beforeDestroy() {
    this.clearTimer()
    this.compareCreationError = false
  },
  mounted() {
    this.$root.$on("toggle-compare-dialogue", () => {
      this.dialog = !this.dialog
    })
    this.updateTitle()
  },
  methods: {
    updateTitle() {
      let beforeDate = this.$moment.tz(this.beforeDate, this.timezone),
        afterDate = this.$moment.tz(this.afterDate, this.timezone)
      this.title = `Evercam - ${this.cameraName} Compare - ${beforeDate.format(
        "Do MMM YYYY"
      )} - ${afterDate.format("Do MMM YYYY")}`
    },
    clearTimer() {
      this.processing = false
      this.created = false
    },
    closeDialog() {
      this.dialog = false
      this.stepper = 1
      this.clearTimer()
    },
    makeRandString() {
      let text = ""
      let possible = "abcdefghijklmnopqrstuvwxyz"
      let i = 0
      while (i < 6) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
        i++
      }

      return text
    },
    async downloadItem(type) {
      const url = this[`${type}Url`]
      this.$analytics.saveEvent(AnalyticsEvent.CompareDownloadAs, {
        fileType: type,
      })
      this[`${type}Loading`] = true
      try {
        const response: Blob = await this.$axios.get(url, {
          params: { download: true },
          responseType: "blob",
        })
        const extension = type
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(response, `${this.title}.${extension}`)
        }
        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(response)
        link.download = this.title + "." + extension
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this[`${type}Loading`] = false
      }
    },
    async autoCheckCompareStatus(compareId, tries) {
      if (!this.dialog) {
        return
      }
      try {
        const compare: Compare = await EvercamApi.compares.cShow(
          this.projectStore.selectedProject?.exid,
          compareId
        )
        if (compare.status === "Completed") {
          this.processing = false
          this.created = true
          this.$notifications.success(this.$t("content.compare.compare_saved"))
        } else if (compare.status === "Processing" && tries < 10) {
          this.$setTimeout(
            () => this.autoCheckCompareStatus(compare.exid, tries + 1),
            10000
          )
        } else {
          this.$notifications.warn(this.$t("content.compare.overtime_warning"))
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createCompare() {
      this.processing = true
      let compareExid = `${this.title
        .replace(/[^A-Z0-9]/gi, "")
        .slice(0, 5)}-${this.makeRandString()}`.toLowerCase()
      let before = this.$moment
        .tz(this.beforeDate, this.timezone)
        .utc()
        .format("YYYY-MM-DD-HH_mm_ss")
      let after = this.$moment
        .tz(this.afterDate, this.timezone)
        .utc()
        .format("YYYY-MM-DD-HH_mm_ss")
      // eslint-disable-next-line no-useless-escape
      this.embedCode = `<div id='evercam-compare'></div><script src='${window.location.origin}/evercam-compare.js' class='${this.cameraExid} ${before} ${after} ${compareExid} autoplay'><\/script>`
      let params: CompareRequestPayload = {
        name: this.title,
        beforeDate: this.$moment
          .tz(this.beforeDate, this.timezone)
          .toISOString(true),
        afterDate: this.$moment
          .tz(this.afterDate, this.timezone)
          .toISOString(true),
        embed: this.embedCode,
        compareExid,
        cameraExid: this.cameraExid,
        createAnimation: true,
        evercamWatermark: this.layoutStore.isEvercamWatermark,
        ...this.options,
      }
      try {
        if (this.options) {
          //If the archive is drone based, we show loading panel before creation
          this.stepper++
        }
        const compare: Compare = await EvercamApi.compares.cCreate(
          this.projectStore.selectedProject?.exid,
          params
        )
        this.mediaHubStore.forceReloadMediaHub()
        if (!this.options) {
          //If not, business as usual
          this.stepper++
        }
        this.gifUrl = `${this.$config.public.apiURL}/projects/${this.projectStore.selectedProject?.exid}/media-hub/${compare.exid}.gif`
        this.mp4Url = `${this.$config.public.apiURL}/projects/${this.projectStore.selectedProject?.exid}/media-hub/${compare.exid}.mp4`
        this.$analytics.saveEvent(AnalyticsEvent.CompareSaveToMediaHub)
        this.$setTimeout(
          () => this.autoCheckCompareStatus(compare.exid, 1),
          10000
        )
      } catch (error) {
        this.compareCreationError = true
        this.$notifications.error({
          text: this.$t("content.compare.create_failed"),
          error,
        })
      }
    },
    onUrlCopy(type) {
      this.$analytics.saveEvent(AnalyticsEvent.CompareCopyDownloadFileUrl, {
        fileType: type,
      })
    },
    goToMediaHub() {
      this.$analytics.saveEvent(AnalyticsEvent.CompareGoToMediaHub)
    },
  },
})
